import {AccountType, blockedEmailRegex, CatalogList, JobCostingPlatform, UserWebSession, WebSessionProp} from './user.types';
import {AuthJSON} from '../../server/authentication/authentication.class';
import {ShippingSaverAdType} from '../ads/ads.types';

export class User {
    private _activeAccount: string;
    private _highestLevelAccount: string;
    private _orderLines: string;
    private _orderNumber: string;
    private _webSession: UserWebSession;
    public exp: number;
    public readonly allowSaver: boolean;
    public readonly authTime: number;
    public readonly authToken: string;
    public readonly caBillTo: boolean;
    public readonly catalogList: CatalogList;
    public readonly city: string;
    public readonly coName: string;
    public readonly contactID: string;
    public readonly contactPhone: string;
    public readonly createSrc: string;
    public readonly DDKey: string;
    public readonly dealerID?: string;
    public readonly defaultCatalogId: string;
    public readonly departmentNumber: string;
    public readonly districtNumber: string;
    public readonly divisionNumber: string;
    public readonly fastrackID: string;
    public readonly fullName: string;
    public readonly hasCurrentPcode: boolean;
    public readonly hasOrderApproval: boolean;
    public readonly hasSampled: boolean;
    public readonly iat: number;
    public readonly impersonate: string;
    public readonly invcDelPref: boolean;
    public readonly invcFirstDt: string;
    public readonly invcLastDt: string;
    public readonly loginAccount: string;
    public readonly loginName: string;
    public readonly marketCode: string;
    public readonly parentnum: string;
    public readonly pbBlockGdsp: boolean;
    public readonly pbs: string;
    public readonly pcodes: boolean;
    public readonly repNumber: string;
    public readonly securityFlags: string;
    public readonly sessionID: string;
    public readonly shippingSaver: string;
    public readonly shippingSaverPaid: string;
    public readonly siteId: string;
    public readonly sortBook?: string;
    public readonly sortField?: string;
    public readonly specials: string;
    public readonly state: string;
    public readonly status: string;
    public readonly xref: string;
    public readonly zip: string;

    // Overwritten by term
    public contactVerify: boolean;
    public ordrconf: string;
    public shipconf: string;

    // Punchout only
    public addressID: string;
    public aDomain: string;
    public aLogin: string;
    public ANID: string;
    public buyerCookie: string;
    public deployMode: string;
    public fromIdentity: string;
    public operation: string;
    public punchid: string;

    constructor(userData) {
        // Assign props from userData
        Object.assign(this, userData);

        // Make securityFlags uppercase
        if (this.securityFlags) {
            this.securityFlags = this.securityFlags.toUpperCase();
        }
    }

    /**
     * Returns "name" for user's currently active account
     */
    public get accountDisplayName(): string {
        return this.keyIdString || this.activeAccount || `No Account Selected`;
    }

    /**
     * Gets activeAccount on the session
     */
    public get activeAccount(): string {
        return this._activeAccount;
    }

    /**
     * Sets activeAccount on the session
     * @param accountNum - Account number to set
     */
    public set activeAccount(accountNum: string) {
        this._activeAccount = accountNum;
    }

    /**
     * Gets activeCatalogId on the session
     */
    public get activeCatalogId(): string {
        return this._getWebSessionValue(`activeCatalogId`) || this.defaultCatalogId;
    }

    /**
     * Sets activeCatalogId on the session
     * @param catalogId - Catalog ID to set
     */
    public set activeCatalogId(catalogId: string) {
        this._setWebSessionValue(`activeCatalogId`, catalogId);
    }

    /**
     * Gets user's current active district
     * @returns - DISTRICT of user
     */
    public get activeDistrict(): string {
        return this.districtNumber || ``;
    }

    /**
     * Gets user's current active division
     * @returns - DIVISION of user
     */
    public get activeDivision(): string {
        return this.divisionNumber || ``;
    }

    /**
     * Gets user's current activeOrder number
     */
    public get activeOrder(): string {
        return this.orderNumber || ``;
    }

    /**
     * Gets user's current active parent
     */
    public get activeParent(): string {
        return this.parentnum || ``;
    }

    /**
     * Gets user's current active parent
     */
    public allowShippingSaver(): boolean {
        return this.allowSaver;
    }

    /**
     * Tests if the user has permission to approve orders (security flag G)
     * @returns - If user canApproveOrders
     */
    public canApproveOrders(): boolean {
        return this.hasSecurity(`G`);
    }

    /**
     * Tests if the user has permission to approve orders (security flag G)
     * @returns - If user canApproveOrders
     */
    public canEditMyShop(): boolean {
        return this.hasSecurity(`H`);
    }

    /**
     * Determines if the user has download order permission
     */
    public canDownloadOrder(): boolean {
        return this.hasSecurity(`D`);
    }

    /**
     * Tests if the user has permission to use invoices (security flag V)
     * @returns - If user canApproveOrders
     */
    public canInvoice(): boolean {
        return this.hasSecurity(`V`);
    }

    /**
     * Tests if user has order permission
     */
    public canOrder(): boolean {
        return this.hasSecurity(`O`);
    }

    /**
     * Tests if user's weblogin is a valid email address
     */
    public get canSendEmail(): boolean {
        if (this.loginName) {
            return (
                this.loginName.indexOf(`@`) >= 0 &&
                !blockedEmailRegex.test(this.loginName) &&
                this.loginName !== `test2@imperialsupplies.com`
            );
        }
        return false;
    }

    /**
     * Can the user sort by price in browse/search functions
     */
    public get canSortByPrice(): boolean {
        return !!this.sortField;
    }

    /**
     * Returns true if user is able to switch accounts
     */
    public canSwitchAccount(): boolean {
        // Return true for normal multi-account users
        if (this.isMultiAccount() && !this.isAllAccountAccess()) {
            return true;
        }

        // Return true for "L" security users at the division level
        if (this.isAllAccountAccess() && this.isDivisionLevel()) {
            return true;
        }

        // Return false by default
        return false;
    }

    /**
     * Tests if the user can view prices (security flag P)
     * @returns - If user canShowPrice
     */
    public canViewPrice(): boolean {
        return this.hasSecurity(`P`);
    }

    /**
     * Clears order state
     */
    public clearOrder(): void {
        this.orderLines = `0`;
        this.orderNumber = undefined;
    }

    /**
     * Clears UserWebSession to avoid retained values
     */
    public clearUserWebSession() {
        this._webSession = {};
    }

    /**
     * Returns user's default catalog name
     */
    public get defaultCatalogName(): string {
        if (this.catalogList && this.catalogList.catalogs) {
            for (const catalog of this.catalogList.catalogs) {
                if (catalog.default === true) {
                    return catalog.name;
                }
            }
        }
        return ``;
    }

    /**
     * Deletes provided webSessionProp
     * @param webSessionProp - Property to delete
     */
    public deleteWebSessionProp(webSessionProp: WebSessionProp) {
        if (this._webSession && this._webSession[webSessionProp]) {
            delete this._webSession[webSessionProp];
        }
    }

    /**
     * Sets drilledAccount on the session
     * @param accountNum - Account number to set
     */
    public set drilledAccount(accountNum: string) {
        this._setWebSessionValue(`drilledAccount`, accountNum);
    }

    /**
     * Gets drilledAccount on the session
     */
    public get drilledAccount(): string {
        return this._getWebSessionValue(`drilledAccount`);
    }

    /**
     * Builds userData from term authentication response
     * @param authJSON - Response from authenticatejson.p
     * @param enteredPassword - Indicates if user entered password
     * @param existingUser - Old user data (for retention)
     */
    public static fromAuthJSON(authJSON: AuthJSON, enteredPassword: boolean, existingUser?: User): User {
        // Build base userData
        const userData: any = {
            _activeAccount: authJSON.account,
            _highestLevelAccount: authJSON.account,
            allowSaver: authJSON.allowSaver,
            authToken: authJSON.pwd,
            caBillTo: authJSON.caBillTo,
            catalogList: authJSON.catalogList,
            city: authJSON.city,
            coName: authJSON.coName,
            contactID: authJSON.contactID,
            contactPhone: authJSON.contactPhone,
            contactVerify: authJSON.contactVerify,
            createSrc: authJSON.createSrc,
            DDKey: authJSON.DDKey,
            dealerID: authJSON.dealerID,
            departmentNumber: authJSON.deptNbr,
            fastrackID: authJSON.fastrackID,
            fullName: authJSON.fullName,
            hasCurrentPcode: authJSON.hasCurrentPcode,
            hasOrderApproval: authJSON.hasOrderApproval,
            hasSampled: authJSON.hasSampled,
            impersonate: authJSON.impersonate,
            invcDelPref: authJSON.invcDelPref,
            invcFirstDt: authJSON.invcFirstDt,
            invcLastDt: authJSON.invcLastDt,
            loginAccount: authJSON.loginAccount,
            loginName: authJSON.email,
            marketCode: authJSON.marketCode,
            ordrconf: authJSON.ordrconf || `yes`,
            parentnum: authJSON.parentDiv,
            pbBlockGdsp: authJSON.pbBlockGdsp,
            pbs: authJSON.prcbks,
            pcodes: authJSON.pcodes === `YES` || false,
            repNumber: authJSON.rep,
            securityFlags: authJSON.securityFlags,
            sessionID: authJSON.sessionID,
            shipconf: authJSON.shipconf || `yes`,
            shippingSaver: authJSON.frtExpDate || ``,
            shippingSaverPaid: authJSON.frtAmtPaid || ``,
            sortBook: authJSON.sortBook,
            sortField: authJSON.sortField,
            specials: authJSON.specials,
            state: authJSON.state,
            status: authJSON.recStatus,
            xref: authJSON.xref,
            zip: authJSON.zip,
        };

        // Update authTime if enteredPassword
        if (enteredPassword) {
            userData.authTime = new Date().valueOf();
        } else {
            userData.authTime = existingUser.authTime;
        }

        // Build webSession
        if (existingUser && existingUser._webSession) {
            userData._webSession = existingUser._webSession;
        } else {
            userData._webSession = {};
        }

        // Build division and district
        if (authJSON.DDKey) {
            const splitKey = authJSON.DDKey.split(`,`);
            userData.divisionNumber = splitKey[0];
            userData.districtNumber = splitKey[1];
        }

        // Set order info
        if (authJSON.ordrNbr) {
            userData._orderLines = authJSON.lines;
            userData._orderNumber = authJSON.ordrNbr;
        }

        // Set catalogIds
        if (authJSON.catalogList && authJSON.catalogList.catalogs && authJSON.catalogList.catalogs.length > 0) {
            userData.defaultCatalogId = authJSON.catalogList.catalogs[0].cid.toString();
        }

        return new User(userData);
    }

    /**
     * Returns account to use for AccountPickerComponent
     */
    public getAccountToList(): string {
        return this.isAllAccountAccess() ? this.highestLevelAccount : null;
    }

    /**
     * Returns accountType from accountNum
     * @param accountNum - Account number to evaluate
     * @returns - Account Type (District, Division or Account)
     */
    public static getAccountType(accountNum: string): AccountType {
        // Handle lower case letters
        accountNum = accountNum.toUpperCase();

        // Validate accountNum
        if (!this.isValidAccountNumber(accountNum)) {
            return null;
        }

        // District
        const accountStartsWithD = accountNum.indexOf(`D`) === 0;
        const accountGreaterThan4Chars = accountNum.length > 4;
        if (accountStartsWithD && accountGreaterThan4Chars) {
            return `District`;
        }

        // Division
        if (accountStartsWithD) {
            return `Division`;
        }

        // Account (Default)
        return `Account`;
    }

    /**
     * Returns the activeAccount type
     * @returns - Account Type (District, Division or Account)
     */
    public getActiveAccountType(): string {
        if (!this.activeAccount) {
            return ``;
        }
        return User.getAccountType(this.activeAccount);
    }

    /**
     * Returns most likely 5 digit shipTo zip code using provided inputs
     * @param zipOverrideCookie - If user has hardcoded a zip
     * @param geoCookie - Geo cookie from Akamai
     * @param user - User session (for zip info)
     */
    public static getCurrentZip(zipOverrideCookie: string, geoCookie: string, user?: User): string {
        // Prefer a zip override cookie first
        if (zipOverrideCookie) {
            return zipOverrideCookie;
        }

        // Next prefer user's zip code on their profile
        if (user?.zip) {
            return user.zip.split(`-`)[0];
        }

        // Next prefer geo cookie from Akamai (formatted as '60045|LAKEFOREST|IL|US')
        if (geoCookie) {
            return geoCookie.split(`|`)[0].split(`-`)[0];
        }

        // Else default to Grainger zip code
        return `60045`;
    }

    /**
     * Returns parent id
     */
    public get getParent(): string {
        if (this.parentnum) {
            return this.parentnum;
        }
        return ``;
    }

    /**
     * Returns which shippingSaver ribbon ad to display
     */
    public getShippingSaverAd(): ShippingSaverAdType {
        // Determine if account user qualifies
        if (this.allowShippingSaver()) {
            const shipSaverExpireDate = this.getShipSaverExpireDate();

            // Never had shipping saver
            if (!shipSaverExpireDate) {
                return `shipping_saver`;
            } else {
                // Renewal range
                const daysAgo7 = new Date();
                daysAgo7.setDate(daysAgo7.getDate() - 7);
                const daysFromNow14 = new Date();
                daysFromNow14.setDate(daysFromNow14.getDate() + 14);

                // In renewal range
                if (shipSaverExpireDate >= daysAgo7 && shipSaverExpireDate <= daysFromNow14) {
                    return `renew_shipping_saver`;

                    // Has current shipping saver
                } else if (shipSaverExpireDate > new Date()) {
                    return ``;

                    // Has expired shipping saver
                } else {
                    return `shipping_saver`;
                }
            }
        }

        // User does not qualify
        return ``;
    }

    /**
     * Gets the amount user paid for shipping saver
     */
    public getShipSaverAmt(): string {
        if (this.shippingSaverPaid) {
            return this.shippingSaverPaid;
        }
        return null;
    }

    /**
     * Gets the date shipping saver will expire
     */
    public getShipSaverExpireDate(): Date {
        if (this.shippingSaver) {
            return new Date(this.shippingSaver);
        }
        return null;
    }

    /**
     * Returns the uppermost account level, if not blacklisted
     */
    public getUppermostAccountLevel(): string {
        const blackListParents = [`000`, `804`, `812`, `828`, `838`, `855`, `881`, `888`, `8CI`, `8PF`, `8TK`, `8VF`, `8HB`];
        const blackListDivisions = [`000`, `010`];
        const blackListDistrict = [`0`];
        if (this.activeParent && blackListParents.indexOf(this.activeParent) === -1) {
            return this.activeParent.toLowerCase();
        } else if (this.activeDivision && blackListDivisions.indexOf(this.activeDivision) === -1) {
            return this.activeDivision.toLowerCase();
        } else if (this.activeDistrict && blackListDistrict.indexOf(this.activeDistrict) === -1) {
            return this.activeDistrict.toLowerCase();
        } else {
            return this.activeAccount.toLowerCase();
        }
    }

    /**
     * Tests if user has an active order
     */
    public hasActiveOrder(): boolean {
        return !!this.activeOrder;
    }

    /**
     * Returns true if shipSaverExpireDate is greater than or equal to today
     */
    public hasActiveShippingSaver(): boolean {
        const shipSaverExpireDate = this.getShipSaverExpireDate();
        if (!this.activeAccount || !shipSaverExpireDate) {
            return false;
        } else {
            return shipSaverExpireDate >= new Date();
        }
    }

    /**
     * Returns true if user has access to A/R
     */
    public hasARAccess(): boolean {
        return this.hasSecurity(`A`);
    }

    /**
     * Returns true if user has custom catalogs available
     */
    public hasCustomCatalogs() {
        return !!(this.catalogList && this.catalogList.catalogs && this.catalogList.catalogs.length > 0);
    }

    /**
     * Returns deals
     */
    public hasDeals(): boolean {
        return this.specials === `YES`;
    }

    /**
     * Returns true if the user has division level access
     */
    public hasDivisionAccess(): boolean {
        if (this.activeAccount || this.highestLevelAccount) {
            return (
                (this.activeAccount && this.activeAccount[0] === `D`) || (this.highestLevelAccount && this.highestLevelAccount[0] === `D`)
            );
        }
        return false;
    }

    /**
     * Returns true if user has job costing feature
     */
    public hasJobCosting() {
        return this.hasSecurity(`J`);
    }

    /**
     * Returns true if user has Repair Order feature
     */
    public hasRepairOrder() {
        return this.hasSecurity(`Z`);
    }

    /**
     * Returns true if user has custom catalogs available
     */
    public hasMultipleCatalogs() {
        return !!(this.catalogList && this.catalogList.catalogs && this.catalogList.catalogs.length > 1);
    }

    /**
     * Reviews list of user's pricebooks against the provided array
     * @param priceBookArray - Array of pricebooks to check
     */
    public hasOneOfThesePriceBooks(priceBookArray: string[]): boolean {
        // Build array of user's assigned pricebooks
        let userPricebooks = [];
        if (this.pbs) {
            userPricebooks = this.pbs.split(`,`);
            userPricebooks = userPricebooks.map((userPriceBook) => userPriceBook.toUpperCase());
        }

        // Determine if the user has one of these pricebooks
        for (let priceBook of priceBookArray) {
            priceBook = priceBook.toUpperCase();
            for (const userPriceBook of userPricebooks) {
                if (priceBook === userPriceBook) {
                    return true;
                }
            }
        }

        // Default
        return false;
    }

    /**
     * Returns true if user has access to order approval
     */
    public get hasOrderApprovalAccess(): boolean {
        return this.hasOrderApproval || this.canApproveOrders();
    }

    /**
     * Generate a hasPriceQuery for this user
     */
    public hasPriceQuery(): string {
        const paramArray = [];
        if (this.pbs && this.pbs !== ``) {
            const PBs = this.pbs.split(`,`);
            PBs.forEach((pb) => {
                paramArray.push(pb.toUpperCase());
            });
        }
        if (this.activeAccount !== `` && this.activeAccount[0] !== `D`) {
            paramArray.push(this.activeAccount);
        }
        if (this.DDKey && this.DDKey !== `000,0`) {
            paramArray.push(this.DDKey.toUpperCase().replace(`,`, `-`));
        }
        if (paramArray.length) {
            return `( ${paramArray.join(` OR `)} )`;
        } else {
            return ``;
        }
    }

    /**
     * Returns true if user has access to sales functionality
     */
    public hasSalesAccess() {
        return this.hasSecurity(`S`);
    }

    /**
     * Tests if the user has the given security flag
     * @param letter {string} Security letter to test for
     */
    public hasSecurity(letter: string): boolean {
        if (!this.securityFlags) {
            return false;
        }
        return this.securityFlags.toUpperCase().indexOf(letter.toUpperCase()) >= 0;
    }

    /**
     * Gets highestLevelAccount on the session
     */
    public get highestLevelAccount(): string {
        return this._highestLevelAccount;
    }

    /**
     * Sets highestLevelAccount on the session
     * @param accountNum - Account number to set
     */
    public set highestLevelAccount(accountNum: string) {
        this._highestLevelAccount = accountNum;
    }

    /**
     * Gets user's current active parent
     */
    public get invoicePrefAccess(): boolean {
        return this.invcDelPref || false;
    }

    /**
     * Returns true if user has admin access on account
     */
    public isAccountAdmin(): boolean {
        return this.hasSecurity(`W`);
    }

    /**
     * Returns true if user at account level
     */
    public isAccountLevel(): boolean {
        if (this.activeAccount) {
            return this.activeAccount[0].toUpperCase() !== `D`;
        }
        return false;
    }

    /**
     * Returns true if user has all account access
     */
    public isAllAccountAccess(): boolean {
        return this.hasSecurity(`L`);
    }

    /**
     * Returns true if user is restricted to their custom catalog
     */
    public isCatalogRestricted(): boolean {
        return this.hasCustomCatalogs() && this.catalogList.nbrCatalogs === 1;
    }

    /**
     * Returns true if the user is currently logged into a division level account
     */
    public isDivisionLevel(): boolean {
        if (this.activeAccount) {
            return User.isDivisionOrDistrictLevel(this.activeAccount);
        }
        return false;
    }

    /**
     * Returns true if the user is currently logged into a district level account
     */
    public isDistrictLevel(): boolean {
        if (this.activeAccount) {
            return User.isDivisionOrDistrictLevel(this.activeAccount) && this.activeAccount?.length === 7;
        }
        return false;
    }

    /**
     * Returns true if provided account is division or district level
     * @param accountNum - Account number to check
     */
    public static isDivisionOrDistrictLevel(accountNum: string): boolean {
        return typeof accountNum === `string` && accountNum[0]?.toUpperCase() === `D`;
    }

    /**
     * Returns true if loginName ends in imperialsupplies.com
     */
    public isImperial(): boolean {
        return this.loginName.toLowerCase().endsWith(`@imperialsupplies.com`);
    }

    /**
     * Returns true if user is impersonating on an account
     */
    public isImpersonating(): boolean {
        return !!this.impersonate;
    }

    /**
     * Returns true if user is impersonating themselves on the account
     */
    public isImpersonatingSelf(): boolean {
        return this.isImpersonating() && this.impersonate === this.loginName;
    }

    /**
     * Returns true if user is in provided parent, division or district
     * @param divisionNum - Parent (D8-W), division (D785) or district (D785001) to check
     */
    public isInDivision(divisionNum: string): boolean {
        try {
            // Validate divisionNum param
            if (!divisionNum || typeof divisionNum !== `string` || divisionNum.length < 2) {
                return false;
            }

            // Remove leading "D"
            divisionNum = divisionNum.slice(1);

            // Check parent (D8-W)
            if (this.parentnum === divisionNum) {
                return true;
            }

            // Check division (D785)
            if (this.divisionNumber === divisionNum) {
                return true;
            }

            // Check district (D785001)
            if (this.districtNumber.length === 1) {
                return `${this.divisionNumber}00${this.districtNumber}` === divisionNum;
            } else if (this.districtNumber.length === 2) {
                return `${this.divisionNumber}0${this.districtNumber}` === divisionNum;
            } else {
                return `${this.divisionNumber}${this.districtNumber}` === divisionNum;
            }
        } catch (e) {
            return false;
        }
    }

    /**
     * Returns true if user is part of field service
     */
    public isFieldService(): boolean {
        return this.hasSecurity(`F`);
    }

    /**
     * Returns true if user is "logged in"
     */
    public isLoggedIn(): boolean {
        return !!this.loginName;
    }

    /**
     * Tests if the user has the multi-account security flag (M)
     */
    public isMultiAccount(): boolean {
        return this.hasSecurity(`M`);
    }

    /**
     * Returns true if the user is currently logged into a Parent level account
     */
    public isParentLevel(): boolean {
        if (this.activeAccount) {
            return this.activeAccount.substring(0, 2) === `D8`;
        }
        return false;
    }

    /**
     * Returns true if user is prospect user
     */
    public get isProspect(): boolean {
        return this.status === 'P';
    }

    /**
     * Returns true if user is punchout user
     */
    public get isPunchout(): boolean {
        return !!(this.ANID || this.punchoutId);
    }

    /**
     * Returns true if user
     */
    public get isSap(): boolean {
        if (this.punchoutType) {
            return this.punchoutType === `sap`;
        }
        return false;
    }

    /**
     * Returns true if this is a transient user
     */
    public get isTransient(): boolean {
        return this._getWebSessionValue(`isTransient`) === `true`;
    }

    /**
     * Sets the user as a transient user
     */
    public set isTransient(shouldBeTransient: boolean) {
        this._setWebSessionValue(`isTransient`, `${shouldBeTransient}`);
    }

    /**
     * Returns true if provided accountNum is valid
     * @param accountNum - Account number to test
     */
    public static isValidAccountNumber(accountNum: string): boolean {
        // Accept only strings
        if (!accountNum || typeof accountNum !== `string`) {
            return false;
        }

        // Test against regex
        const validAccountNumRegex = /^([A-Z0-9\-_]{7}|[A-Z0-9\-_]{4})$/i;
        return validAccountNumRegex.test(accountNum);
    }

    /**
     * Gets keyIdString on the session
     */
    public get keyIdString(): string {
        return this._getWebSessionValue(`keyIdString`);
    }

    /**
     * Sets keyIdString on the session
     * @param keyIdString - Key ID string to set
     */
    public set keyIdString(keyIdString: string) {
        this._setWebSessionValue(`keyIdString`, keyIdString);
    }

    /**
     * Returns lines on the session
     */
    public get orderLines(): string {
        return this._getWebSessionValue(`orderLines`) || this._orderLines || ``;
    }

    /**
     * Sets lines on the session
     */
    public set orderLines(numLines: string) {
        this._setWebSessionValue(`orderLines`, numLines);
        this._orderLines = numLines;
    }

    /**
     * Gets orderNumber on the session
     */
    public get orderNumber(): string {
        return this._getWebSessionValue(`orderNumber`) || this._orderNumber || ``;
    }

    /**
     * Sets orderNumber on the session
     * @param orderNumber - Order number to set
     */
    public set orderNumber(orderNumber: string) {
        this._setWebSessionValue(`orderNumber`, orderNumber);
        this._orderNumber = orderNumber;
    }

    /**
     * Returns full name for user's punchout app
     */
    public get punchoutAppName(): string {
        switch (this.punchoutType) {
            case `oracle`:
                return `Oracle Exchange`;
            case `aeroxchange`:
                return `AeroXchange`;
            case `sap`:
                return `SAP SRM`;
            case `peoplesoft`:
                return `PeopleSoft`;
            case `ketera`:
                return `Ketera`;
            case `smartequip`:
                return `SmartEquip`;
            case `coupa`:
                return `Coupa`;
            case `vinimaya`:
                return `Vinimaya`;
            case `ivalua`:
                return `iValua`;
            case `dynamics`:
                return `MS Dynamics`;
            case `workday`:
                return `Workday`;
            case `tesla`:
                return `WarpDrive`;
            case `shopfront`:
                return `ShopFront`;
            default:
                return `Procurement System`;
        }
    }

    /**
     * Gets the user's punchid
     */
    public get punchoutId(): string {
        return this.punchid || ``;
    }

    /**
     * Returns type of punchout
     */
    public get punchoutType(): string {
        if (this.aDomain) {
            return this.aDomain.toLowerCase();
        }
        return ``;
    }

    /**
     * Returns user's restricted catalogs
     */
    public get restrictedCatalogs(): string[] {
        const restrictedCatalogs = [];
        if (this.hasCustomCatalogs() && this.catalogList.nbrCatalogs > 1) {
            for (const catalog of this.catalogList.catalogs) {
                if (!catalog.default) {
                    restrictedCatalogs.push(catalog.cid.toString());
                }
            }
        }
        return restrictedCatalogs;
    }

    /**
     * Sets selectedContact on the session
     * @param contactID - Contact ID to set
     */
    public set selectedContact(contactID: string) {
        this._setWebSessionValue(`selectedContact`, contactID);
    }

    /**
     * Gets selectedContact on the session
     */
    public get selectedContact(): string {
        return this._getWebSessionValue(`selectedContact`);
    }

    /**
     * Returns true if shipping saver is current or less than 60 days expired
     */
    public showShipSaverWidget(): boolean {
        const shipSaverExpireDate = this.getShipSaverExpireDate();
        if (!this.activeAccount || !shipSaverExpireDate) {
            return false;
        } else {
            const daysAgo60 = new Date();
            daysAgo60.setDate(daysAgo60.getDate() - 60);
            return shipSaverExpireDate >= daysAgo60;
        }
    }

    /**
     * Gets snowflake_id on the session
     */
    public get snowflake_id(): string {
        return this._getWebSessionValue(`snowflake_id`);
    }

    /**
     * Sets snowflake_id on the session
     * @param emailHash - email hash ot set
     */
    public set snowflake_id(emailHash: string) {
        this._setWebSessionValue(`snowflake_id`, emailHash);
    }

    /**
     * Gets UIDHash on the session
     */
    public get UIDHash(): string {
        return this._getWebSessionValue(`UIDHash`);
    }

    /**
     * Sets UIDHash on the session
     * @param emailHash email hash to set
     */
    public set UIDHash(emailHash: string) {
        this._setWebSessionValue(`UIDHash`, emailHash);
    }

    /**
     * Returns true if selectAccountMode should be used
     */
    public useSelectAccountMode(): boolean {
        // Return true if no active account
        if (!this.activeAccount) {
            return true;
        }

        // Return true if highestLevelAccount is account level
        if (User.getAccountType(this.highestLevelAccount) === `Account`) {
            return true;
        }

        // Else do not use selectAccountMode
        return false;
    }

    public hasRecentOrders(): boolean {
        const lastInvoice = new Date(this.invcLastDt);
        const today = new Date();
        const fiveYearsAgo = new Date(today.getFullYear() - 5, today.getMonth(), today.getDate());
        return lastInvoice > fiveYearsAgo;
    }

    /**
     * Sets provided web session value
     * @param webSessionProp - Web session property to set
     * @param webSessionValue - Value to set in session
     * @private
     */
    private _setWebSessionValue(webSessionProp: WebSessionProp, webSessionValue: string) {
        // Reverse compat
        if (!this._webSession) {
            this._webSession = {};
        }

        // Set webSessionProp value
        this._webSession[webSessionProp] = webSessionValue;
    }

    /**
     * Returns requested web session value
     * @param webSessionProp - Web session property to return
     * @private
     */
    private _getWebSessionValue(webSessionProp: WebSessionProp): string {
        if (this._webSession && this._webSession[webSessionProp]) {
            return this._webSession[webSessionProp];
        }
        return ``;
    }

    /**
     * Returns jobCostingPlatform the user is on
     */
    public get jobCostingPlatform(): JobCostingPlatform {
        if (this.fastrackID) {
            return `fastrack`;
        }
        if (this.dealerID) {
            return `cdk`;
        }
        return `imperial`;
    }
}
